<template>
  <div class="_bg-default _80w _100vh pb-3 px-3">
    <div class="_100top">
      <div class="d-flex align-center justify-end flex-column">
        <img
          class="mb-5"
          src="../../assets/img/404.svg"
          height="200px"
          alt=""
        />
        <div class="d-flex justify-end align-center flex-column">
          <h2 class="mb-5 z">404</h2>
        </div>
        <h3 class="mb-5">Oops. The page you're looking for doesn't exist.</h3>
        <v-btn color="#7B1FA2" to="/" dark>
          <v-icon class="mr-2">mdi-home-circle-outline</v-icon>
          <h4>Take me home.</h4></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListNotification",

  data() {
    return {
      currentPage: 1,
      limit: 10
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style scoped>
.__overflow__hidden {
  overflow: hidden !important;
  box-sizing: border-box;
}
.__template__cover {
  width: 100%;
  height: 230px;
  background: #f3e5f5;
}
.__relative {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.cover__ {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.__full {
  height: 100vh;
}
.__10 {
  height: 10vh;
}
.__40 {
  height: 40vh;
}
.__half {
  height: 50vh;
}
.footer {
  position: relative;
}
.svg {
  position: absolute;
  bottom: 0;
}
.z {
  z-index: 2;
}
</style>
